// state that comes from environment variables when running the UI in a docker container
// this works because the container entrypoint is variable_replace.sh which
// does envsubst on all js files
const containerState = {
    AM_URL: '$AM_URL',
    AM_ADMIN_URL: '$AM_ADMIN_URL',
    ANALYTICS_API_URL: '$ANALYTICS_API_URL',
    ENABLE_ANALYTICS_DASH: '$ENABLE_ANALYTICS_DASH',
    IDM_REST_URL: '$IDM_REST_URL',
    IDM_ADMIN_URL: '$IDM_ADMIN_URL',
    IDM_UPLOAD_URL: '$IDM_UPLOAD_URL',
    IDM_EXPORT_URL: '$IDM_EXPORT_URL',
    ENDUSER_UI_URL: '$ENDUSER_UI_URL',
};
  
export default {
    env: containerState
};
