<template>
    <BContainer fluid>
        <BFormRow v-if="poiField" class="mb-2"><BCol cols="12">
            <BCard no-body header="Privacy of Information Statement" class="pb-3 fullwidth">
                <BCardBody>
                    <BFormCheckbox value="true" plain="true"
                        v-on="{
                            'next-step': (event, preventClear) => {
                            nextStep(event, preventClear);
                            },
                            ...poiField.listeners}"> <!-- vchu1: richmond.ca new brand: plain="true" stops a shadow checkbox from being render due to a style conflict with richmond.ca style -->
                        I accept the following <!-- vchu1: richmond.ca new brand: pdficon is part of style now:  <img src='images/pdficon.gif' /> --> <a href='https://richmond.ca/__shared/assets/myrichmondprivacypolicy54739.pdf' target='_blank'>Privacy of Information Statement</a>
                    </BFormCheckbox>
                </BCardBody>
            </BCard>
        </BCol></BFormRow>

        <BFormRow v-if="userNameField" class="mb-2"><BCol cols="12">
            <BCard no-body header="User Information" class="pb-3 fullwidth">
                <BCardBody>
                    <Component
                        class="callback-component"
                        :callback="userNameField.callback"
                        :index="userNameField.index"
                        :is="userNameField.type"
                        :key="userNameField.key"
                        :step="step"
                        v-bind="{...userNameField.callbackSpecificProps}"
                        v-on="{
                            'next-step': (event, preventClear) => {
                            nextStep(event, preventClear);
                            },
                            ...userNameField.listeners}" />
                    <div v-if="stepStage==='regGetLoginEmail'">Enter email address for registration</div>
                    
                    <Component
                        v-if="givenNameField"
                        class="callback-component"
                        :callback="givenNameField.callback"
                        :index="givenNameField.index"
                        :is="givenNameField.type"
                        :key="givenNameField.key"
                        :step="step"
                        v-bind="{...givenNameField.callbackSpecificProps}"
                        v-on="{
                            'next-step': (event, preventClear) => {
                            nextStep(event, preventClear);
                            },
                            ...givenNameField.listeners}" />
                    
                    <Component
                        v-if="snField"
                        class="callback-component"
                        :callback="snField.callback"
                        :index="snField.index"
                        :is="snField.type"
                        :key="snField.key"
                        :step="step"
                        v-bind="{...snField.callbackSpecificProps}"
                        v-on="{
                            'next-step': (event, preventClear) => {
                            nextStep(event, preventClear);
                            },
                            ...snField.listeners}" />

                    <Component
                        v-if="dobField"
                        class="callback-component"
                        :callback="dobField.callback"
                        :index="dobField.index"
                        :is="dobField.type"
                        :key="dobField.key"
                        :step="step"
                        v-bind="{...dobField.callbackSpecificProps}"
                        v-on="{
                            'next-step': (event, preventClear) => {
                            nextStep(event, preventClear);
                            },
                            ...dobField.listeners}" />
                    <div v-if="dobField" class="small text-muted" style="padding-bottom:1em">Date of birth must be YYYYMMDD, ex. 19801220 for December 20, 1980</div>
                    
                    <!-- vchu1: Passkey support -->
                    <Component
                        v-if="passwordField && isLegacy"
                        class="callback-component"
                        :callback="passwordField.callback"
                        :index="passwordField.index"
                        :is="passwordField.type"
                        :key="passwordField.key"
                        :step="step"
                        v-bind="{...passwordField.callbackSpecificProps}"
                        v-on="{
                            'next-step': (event, preventClear) => {
                            nextStep(event, preventClear);
                            },
                            ...passwordField.listeners}" />
                </BCardBody>
            </BCard>
        </BCol></BFormRow>

        <BFormRow class="mb-2" v-if="homePhoneField" ><BCol cols="12">
            <BCard no-body header-tag="header" class="pb-3 fullwidth">
                <template #header>
                    <div v-b-toggle.collapse-phone>
                        <span class="when-closed">[+]</span><span class="when-open">[-]</span>
                        Optional: Phone Numbers</div>
                </template>
                <BCollapse id="collapse-phone">
                    <BCardBody>
                        <Component
                            v-if="homePhoneField"
                            class="callback-component"
                            :callback="homePhoneField.callback"
                            :index="homePhoneField.index"
                            :is="homePhoneField.type"
                            :key="homePhoneField.key"
                            :step="step"
                            v-bind="{...homePhoneField.callbackSpecificProps}"
                            v-on="{
                                'next-step': (event, preventClear) => {
                                nextStep(event, preventClear);
                                },
                                ...homePhoneField.listeners}" />

                        <Component
                            v-if="mobileField"
                            class="callback-component"
                            :callback="mobileField.callback"
                            :index="mobileField.index"
                            :is="mobileField.type"
                            :key="mobileField.key"
                            :step="step"
                            v-bind="{...mobileField.callbackSpecificProps}"
                            v-on="{
                                'next-step': (event, preventClear) => {
                                nextStep(event, preventClear);
                                },
                                ...mobileField.listeners}" />

                        <Component
                            v-if="telephoneNumberField"
                            class="callback-component"
                            :callback="telephoneNumberField.callback"
                            :index="telephoneNumberField.index"
                            :is="telephoneNumberField.type"
                            :key="telephoneNumberField.key"
                            :step="step"
                            v-bind="{...telephoneNumberField.callbackSpecificProps}"
                            v-on="{
                                'next-step': (event, preventClear) => {
                                nextStep(event, preventClear);
                                },
                                ...telephoneNumberField.listeners}" />
                    </BCardBody>
                </BCollapse>
            </BCard>
        </BCol></BFormRow>

        <BFormRow class="mb-2" v-if="unitField"><BCol cols="12">
            <BCard no-body header-tag="header" class="pb-3 fullwidth">
                <template #header>
                    <div v-b-toggle.collapse-home>
                        <span class="when-closed">[+]</span><span class="when-open">[-]</span>
                        Optional: Mailing Address</div>
                </template>
                <BCollapse id="collapse-home">
                    <BCardBody>
                        <Component
                            v-if="unitField"
                            class="callback-component"
                            :callback="unitField.callback"
                            :index="unitField.index"
                            :is="unitField.type"
                            :key="unitField.key"
                            :step="step"
                            v-bind="{...unitField.callbackSpecificProps}"
                            v-on="{
                                'next-step': (event, preventClear) => {
                                nextStep(event, preventClear);
                                },
                                ...unitField.listeners}" />

                        <Component
                            v-if="houseNumberField"
                            class="callback-component"
                            :callback="houseNumberField.callback"
                            :index="houseNumberField.index"
                            :is="houseNumberField.type"
                            :key="houseNumberField.key"
                            :step="step"
                            v-bind="{...houseNumberField.callbackSpecificProps}"
                            v-on="{
                                'next-step': (event, preventClear) => {
                                nextStep(event, preventClear);
                                },
                                ...houseNumberField.listeners}" />

                        <Component
                            v-if="streetField"
                            class="callback-component"
                            :callback="streetField.callback"
                            :index="streetField.index"
                            :is="streetField.type"
                            :key="streetField.key"
                            :step="step"
                            v-bind="{...streetField.callbackSpecificProps}"
                            v-on="{
                                'next-step': (event, preventClear) => {
                                nextStep(event, preventClear);
                                },
                                ...streetField.listeners}" />
                        
                        <Component
                            v-if="addl1Field"
                            class="callback-component"
                            :callback="addl1Field.callback"
                            :index="addl1Field.index"
                            :is="addl1Field.type"
                            :key="addl1Field.key"
                            :step="step"
                            v-bind="{...addl1Field.callbackSpecificProps}"
                            v-on="{
                                'next-step': (event, preventClear) => {
                                nextStep(event, preventClear);
                                },
                                ...addl1Field.listeners}" />
                        
                        <Component
                            v-if="addl2Field"
                            class="callback-component"
                            :callback="addl2Field.callback"
                            :index="addl2Field.index"
                            :is="addl2Field.type"
                            :key="addl2Field.key"
                            :step="step"
                            v-bind="{...addl2Field.callbackSpecificProps}"
                            v-on="{
                                'next-step': (event, preventClear) => {
                                nextStep(event, preventClear);
                                },
                                ...addl2Field.listeners}" />
                        
                        <Component
                            v-if="cityField"
                            class="callback-component"
                            :callback="cityField.callback"
                            :index="cityField.index"
                            :is="cityField.type"
                            :key="cityField.key"
                            :step="step"
                            v-bind="{...cityField.callbackSpecificProps}"
                            v-on="{
                                'next-step': (event, preventClear) => {
                                nextStep(event, preventClear);
                                },
                                ...cityField.listeners}" />
                        
                        <Component
                            v-if="provField"
                            class="callback-component"
                            :callback="provField.callback"
                            :index="provField.index"
                            :is="provField.type"
                            :key="provField.key"
                            :step="step"
                            v-bind="{...provField.callbackSpecificProps}"
                            v-on="{
                                'next-step': (event, preventClear) => {
                                nextStep(event, preventClear);
                                },
                                ...provField.listeners}" />
                        
                        <Component
                            v-if="countryField"
                            class="callback-component"
                            :callback="countryField.callback"
                            :index="countryField.index"
                            :is="countryField.type"
                            :key="countryField.key"
                            :step="step"
                            v-bind="{...countryField.callbackSpecificProps}"
                            v-on="{
                                'next-step': (event, preventClear) => {
                                nextStep(event, preventClear);
                                },
                                ...countryField.listeners}" />
                        
                        <Component
                            v-if="postalField"
                            class="callback-component"
                            :callback="postalField.callback"
                            :index="postalField.index"
                            :is="postalField.type"
                            :key="postalField.key"
                            :step="step"
                            v-bind="{...postalField.callbackSpecificProps}"
                            v-on="{
                                'next-step': (event, preventClear) => {
                                nextStep(event, preventClear);
                                },
                                ...postalField.listeners}" />
                    </BCardBody>
                </BCollapse>
            </BCard>
        </BCol></BFormRow>

        <BFormRow v-if="comm1Field" class="mb-2"><BCol cols="12">
            <BCard header="Opt-in Communication Options" class="pb-3 fullwidth">
                <BCardBody>
                    <div class="mb-2">By selecting yes, you consent to...</div>
                    <BFormCheckbox value="true" plain="true" class="mb-2" style="text-align:left"
                        v-on="{
                        'next-step': (event, preventClear) => {
                        nextStep(event, preventClear);
                        },
                        ...comm1Field.listeners}">
                        receiving email regarding community programs, services and events from the City of Richmond
                    </BFormCheckbox>
                    <BFormCheckbox value="true" plain="true" style="text-align:left"
                        v-on="{
                        'next-step': (event, preventClear) => {
                        nextStep(event, preventClear);
                        },
                        ...comm2Field.listeners}">
                        receiving email regarding City news releases and announcements</BFormCheckbox>
                    <div class="mt-3 sm">You may update these opt-in communication consent settings any time after this registration process at MyRichmond Profile & Settings</div>
                </BCardBody>
            </BCard>
        </BCol></BFormRow>

        <BFormRow v-if="uatField" class="mb-2"><BCol cols="12">
            <BCard header="User Agreement Terms" class="pb-3 fullwidth">
                <BCardBody>
                    <BFormCheckbox value="true" plain="true"
                        v-on="{
                            'next-step': (event, preventClear) => {
                            nextStep(event, preventClear);
                            },
                            ...uatField.listeners}">
                        I accept the following <!-- vchu1: richmond.ca new brand: pdficon is part of style now: <img src='/images/pdficon.gif' /> --> <a href='https://richmond.ca/__shared/assets/profileterms46203.pdf' target='_blank'>User Agreement Terms</a>
                    </BFormCheckbox>
                </BCardBody>
            </BCard>
        </BCol></BFormRow>

        <BFormRow class="mb-2"><BCol cols="12">
            <template v-for="component in extraComponents ">
                <!-- <div v-for="ccc in componentList ">{{ ccc.type }}</div> -->
                <Component
                class="callback-component"
                :callback="component.callback"
                :index="component.index"
                :is="component.type"
                :key="component.key"
                :step="step"
                v-bind="{...component.callbackSpecificProps}"
                v-on="{
                    'next-step': (event, preventClear) => {
                    nextStep(event, preventClear);
                    },
                    ...component.listeners}" />
            </template>
        </BCol></BFormRow>
    </BContainer>

</template>

<script>
import {
    each,
    find,
    has,
    isString,
    noop,
} from 'lodash';
import {
    BButton,
    BCard,
    BCardBody,
    BCardFooter,
    BCardHeader,
    BCardGroup,
    BCol,
    BCollapse,
    BContainer,
    BFormCheckbox,
    BFormInput,
    BFormRow,
    BLink,
    BRow
} from 'bootstrap-vue';
import Vue from 'vue';
import { VBTogglePlugin } from 'bootstrap-vue';
Vue.use(VBTogglePlugin);

import {
    CallbackType,
    FRAuth,
    FRRecoveryCodes,
    FRStep,
    FRWebAuthn,
    SessionManager,
    WebAuthnStepType,
} from '@forgerock/javascript-sdk';

import FrCenterCard from '@forgerock/platform-shared/src/components/CenterCard';
import Spinner from '@forgerock/platform-shared/src/components/Spinner';
import FrAlert from '@forgerock/platform-shared/src/components/Alert';
import NotificationMixin from '@forgerock/platform-shared/src/mixins/NotificationMixin';
import LoginMixin from '@forgerock/platform-shared/src/mixins/LoginMixin';
import RestMixin from '@forgerock/platform-shared/src/mixins/RestMixin';
import TranslationMixin from '@forgerock/platform-shared/src/mixins/TranslationMixin';
import { getThemeIdFromStageString } from '@forgerock/platform-shared/src/utils/stage';
import i18n from '@/i18n';
import containerState from '@/containerState';

const FrCallbackType = {
    ...CallbackType,
    RecoveryCodesComponent: 'RecoveryCodesComponent',
    RedirectCallback: 'RedirectCallback',
    SelectIdPCallback: 'SelectIdPCallback',
    SuspendedTextOutputCallback: 'SuspendedTextOutputCallback',
    WebAuthnComponent: 'WebAuthnComponent',
};


/* import the fontawesome core
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
library.add(faAngleUp, faAngleDown);
*/

export default {
    name: 'Login',
    components: {
        BButton,
        BCard,
        BCardBody,
        BCardFooter,
        BCardGroup,
        BCardHeader,
        BCol,
        BCollapse,
        BContainer,
        BFormCheckbox,
        BFormInput,
        BFormRow,
        BLink,
        BRow,
        FrAlert,
        FrCenterCard,
        Spinner,
        FrBooleanAttributeInputCallback: () => import('@/components/callbacks/BooleanAttributeInputCallback'),
        FrChoiceCallback: () => import('@/components/callbacks/ChoiceCallback'),
        FrConfirmationCallback: () => import('@/components/callbacks/ConfirmationCallback'),
        FrConsentMappingCallback: () => import('@/components/callbacks/ConsentMappingCallback'),
        FrDeviceProfileCallback: () => import('@/components/callbacks/DeviceProfileCallback'),
        FrField: () => import('@forgerock/platform-shared/src/components/Field'),
        FrHiddenValueCallback: () => import('@/components/callbacks/HiddenValueCallback'),
        FrKbaCreateCallback: () => import('@/components/callbacks/KbaCreateCallback'),
        FrPasswordCallback: () => import('@/components/callbacks/PasswordCallback'),
        FrPollingWaitCallback: () => import('@/components/callbacks/PollingWaitCallback'),
        FrReCaptchaCallback: () => import('@/components/callbacks/ReCaptchaCallback'),
        FrRecoveryCodesComponent: () => import('@/components/display/RecoveryCodes'),
        FrSelectIdPCallback: () => import('@/components/callbacks/SelectIdPCallback'),
        FrSuspendedTextOutputCallback: () => import('@/components/callbacks/SuspendedTextOutputCallback'),
        FrTermsAndConditionsCallback: () => import('@/components/callbacks/TermsAndConditionsCallback'),
        FrTextOutputCallback: () => import('@/components/callbacks/TextOutputCallback'),
        FrValidatedCreatePasswordCallback: () => import('@/components/callbacks/ValidatedCreatePasswordCallback'),
        FrWebAuthnComponent: () => import('@/components/display/WebAuthn'),
    },
    /*directives: {
        VBToggle
    },*/
    props: {
        buttonText: {
        type: String,
        default: '',
        },
        journeyFooter: {
        type: String,
        default: '',
        },
        journeyFooterEnabled: {
        type: Boolean,
        default: false,
        },
        journeyHeader: {
        type: String,
        default: '',
        },
        journeyHeaderEnabled: {
        type: Boolean,
        default: false,
        },
        journeyTheaterMode: {
        type: Boolean,
        default: false,
        },
        journeyJustifiedContent: {
        type: String,
        default: '',
        },
        journeyJustifiedContentEnabled: {
        type: Boolean,
        default: false,
        },
        journeyLayout: {
        type: String,
        default: 'card',
        },
        journeySignInButtonPosition: {
        type: String,
        default: 'flex-column',
        },
        logoAltText: {
        type: String,
        default: '',
        },
        logoEnabled: {
        type: Boolean,
        default: true,
        },
        logoHeight: {
        type: String,
        default: '40',
        },
        logoPath: {
        type: String,
        default: '',
        },
        themeLoading: {
        type: Boolean,
        default: false,
        },
        /* Earlier versions of Registration is embedded in Login so these are passed in as props: */
        componentList: {
            type: Array
        },
        step: {
            type: Object
        }
    },
    mixins: [
        NotificationMixin,
        RestMixin,
        LoginMixin,
        TranslationMixin,
    ],
    data() {
        //vchu1 debug: console.log("reg data stage:", this.stage);
        //vchu1 debug: console.log("reg data step:", this.step);

        // if the step has a password input, then this is legacy auth tree where password is required
        let hasPasswordInStep = this.step.getCallbacksOfType("ValidatedCreatePasswordCallback").length > 0;
        let isPasskeyAware = false;

        let components = this.componentList;
        if (components) {
            let poiField = this.searchField("acceptedPOIStatement");
            let userNameField = this.searchField("[ValidatedCreateUsernameCallback]");
            if (!userNameField) {
                userNameField = this.searchField("[NameCallback]");
            }
            // This differs from isLoginScreen which test if the tree is loginConsent and not necessary which stage of the login it is at
            let isLogin = false;
            // Self-service registration relies on the auth tree to set up 'stage':
            //   regGetLoginEmail - the step in the tree where the email address is requested (username)
            //   regGetUserInfo - the step in the tree where verify email is done and UI is now collecting user info
            let stage = this.step ? (this.step.payload ? this.step.payload.stage : "") : "";
            if (stage === "regGetUserInfo") {
                // Important: if at stage regGetUserInfo, hardcode to take the first TextOutputCallback as username
                // In the tree, this is a "Display Username" node
                userNameField = this.searchField("[TextOutputCallback]");
            } else if ((stage === "login") || (stage === "login2") || (stage === "usernamepassword")) {
                // stage "login" is the original loginConsent login stage
                // stage "login2" is the Passkey-enabled loginConsent login stage
                isLogin = true;
                // vchu1: login page will not be aware of passkey anymore: isPasskeyAware = (stage === "login2")||(stage === "usernamepassword");
            }
            //console.log('userNameField: ', userNameField);  //vchu1 debug
            let passwordField = this.searchField("[ValidatedCreatePasswordCallback]");
            let givenNameField = this.searchField("givenName");
            let snField = this.searchField("sn");
            let dobField = this.searchField("dob");
            let homePhoneField = this.searchField("homePhone");
            let mobileField = this.searchField("mobile");
            let telephoneNumberField = this.searchField("telephoneNumber");
            let unitField = this.searchField("homeUnitNumber");
            let houseNumberField = this.searchField("homeHouseNumber");
            let streetField = this.searchField("homeStreetName");
            let addl1Field = this.searchField("homeAddlInfo1");
            let addl2Field = this.searchField("homeAddlInfo2");
            let cityField = this.searchField("homeCity");
            let provField = this.searchField("homeProvince");
            let countryField = this.searchField("homeCountry");
            let postalField = this.searchField("homePostalCode");
            let comm1Field = this.searchField("CommOpt1");
            let comm2Field = this.searchField("CommOpt2");
            let uatField = this.searchField("acceptedUATerms");

            if (poiField) {
                // Change poiField type to checkbox
                poiField.callback.type = 'BooleanAttributeInputCallback';
            }

            let rdata = {
                /* vchu1: step is a prop in the original registration code:
                componentList: [],
                */
                description: '',
                errorMessage: '',
                header: '',
                hiddenValueCallbacksRefs: [],
                linkToTreeStart: '',
                loading: true,
                loginFailure: false,
                nextButtonDisabledArray: [false],
                nextButtonVisible: false,
                nextStepCallbacks: [],
                nodeThemeId: undefined,
                realm: '/cor',  // vchu1: assume customer realm by default
                retry: undefined,
                showScriptElms: false,
                /* vchu1: step is a prop in the original registration code:
                step: undefined,
                */
                suspendedId: undefined,
                treeId: undefined,

                // cor customizations
                stepStage: stage,
                poiField: poiField,
                userNameField: userNameField,
                passwordField: passwordField,
                givenNameField: givenNameField,
                snField: snField,
                dobField: dobField,
                homePhoneField: homePhoneField,
                mobileField: mobileField,
                telephoneNumberField: telephoneNumberField,
                unitField: unitField,
                houseNumberField: houseNumberField,
                streetField: streetField,
                addl1Field: addl1Field,
                addl2Field: addl2Field,
                cityField: cityField,
                provField: provField,
                countryField: countryField,
                postalField: postalField,
                comm1Field: comm1Field,
                comm2Field: comm2Field,
                uatField: uatField,
                isLogin: isLogin,
                isLegacy: hasPasswordInStep,
                isPasskeyAware: isPasskeyAware,
            };
            return rdata;
        } else {

            let rdata = {
                /* vchu1: step is a prop in the original registration code:
                componentList: [],
                */
                description: '',
                errorMessage: '',
                header: '',
                hiddenValueCallbacksRefs: [],
                linkToTreeStart: '',
                loading: true,
                loginFailure: false,
                nextButtonDisabledArray: [false],
                nextButtonVisible: false,
                nextStepCallbacks: [],
                nodeThemeId: undefined,
                realm: '/cor',  // vchu1: assume customer realm by default
                retry: undefined,
                showScriptElms: false,
                /* vchu1: step is a prop in the original registration code:
                step: undefined,
                */
                suspendedId: undefined,
                treeId: undefined,

                // cor customizations
                stepStage: "",
                poiField: "",
                userNameField: "",
                passwordField: "",
                givenNameField: "",
                snField: "",
                dobField: "",
                homePhoneField: "",
                mobileField: "",
                telephoneNumberField: "",
                unitField: "",
                houseNumberField: "",
                streetField: "",
                addl1Field: "",
                addl2Field: "",
                cityField: "",
                provField: "",
                countryField: "",
                postalField: "",
                comm1Field: "",
                comm2Field: "",
                uatField: "",
                isLogin: false,
                isLegacy: hasPasswordInStep,
                isPasskeyAware: isPasskeyAware
            };
            return rdata;
        }
    },
    mounted() {
        /* vchu1: use this code if Registration is the ROOT view
        const urlParams = new URLSearchParams(window.location.search);
        this.$emit('component-ready');
        this.realm = urlParams.get('realm') || '/cor';  // assume customer realm by default

        this.getConfigurationInfo(this.realm)
        .then((config) => {
            this.setRealm(config);
        })
        .then(() => {
            this.checkNewSession()
        })
        .then(() => {
            this.evaluateUrlParams();
            this.nextStep();
        })
        .then(() => {
            if (this.componentList) {
                // setup special fields with initial values
                const commOpt1 = this.searchField("CommOpt1");
                if (commOpt1) commOpt1.callback.setInputValue("false");
                const commOpt2 = this.searchField("CommOpt2");
                if (commOpt2) commOpt2.callback.setInputValue("false");
                const poi = this.searchField("acceptedPOIStatement");
                if (poi) poi.callback.setInputValue("false");
                const uat = this.searchField("acceptedUATerms");
                if (uat) uat.callback.setInputValue("false");
            }
        })
        .catch((e) => {
            console.log(e);
            this.errorMessage = this.$t('login.invalidRealm');
            this.redirectToFailure(this.step);
            this.loading = false;
        });
        */

        // vchu1: use this code if Registration is an embedded template inside Login view
        // setup special fields with initial values
        const commOpt1 = this.searchField("CommOpt1");
        if (commOpt1) commOpt1.callback.setInputValue("false");
        const commOpt2 = this.searchField("CommOpt2");
        if (commOpt2) commOpt2.callback.setInputValue("false");
        const poi = this.searchField("acceptedPOIStatement");
        if (poi) poi.callback.setInputValue("false");
        const uat = this.searchField("acceptedUATerms");
        if (uat) uat.callback.setInputValue("false");
    },
    methods: {
    /**
     * @description handler for scripts clicking on loginButton_0
     * if a script clicks on loginButton_0 we want to make sure that the
     * the hiddenValue elements values are added to the callback data structure since
     * that is what will be sent with the request
     * this function iterates through the hiddenValue dom refs to accomplish that
     */
     backendScriptsHandler() {
      this.hiddenValueCallbacksRefs.forEach((ref) => {
        const refId = ref.id;
        const refValue = ref.value;
        this.step
          .getCallbacksOfType(FrCallbackType.HiddenValueCallback)
          .find((x) => x.getOutputByName('id', '') === refId)
          .setInputValue(refValue);
      });
      this.nextStep();
    },
    backendScriptsIdsContains(matcher) {
      const typeArr = this.step.getCallbacksOfType(FrCallbackType.HiddenValueCallback)
        .map((callback) => callback.getOutputByName('id', ''));
      return typeArr.indexOf(matcher) >= 0;
    },
    buildTreeForm() {
        //console.log("bTF route: ", this.$route);  // vchu1 debug
        //console.log("tree: ", this.treeId); // vchu1 debug: this.treeId || this.$route.params.tree
        //console.log("stage:", this.stage);
        // tree: CORPlatformRegistration
        // tree: undefined (when just login page), this.$route.params.tree=undefined, this.$route.path = '/'
        //console.log("options: ", this.$options); // vchu1 debug
        this.header = this.step.getHeader() || '';
        this.description = this.$sanitize(this.step.getDescription() || '');
        this.nextButtonVisible = true;
        this.nextButtonDisabledArray = [false];

        let authTree = this.treeId || this.$route.params.tree;

        this.checkNodeForThemeOverride(this.stage);

        this.isMaintenance = this.stage === 'maintenance';
        this.isLoginScreen = (authTree == undefined) || (authTree === 'loginConsent') || (authTree === 'loginConsent2FA');
        // This differs from isLoginScreen which test if the tree is loginConsent and not necessary which stage of the login it is at
        this.isLogin = false;
        let stage = this.step ? (this.step.payload ? this.step.payload.stage : "") : "";
        // stage "login" is the original loginConsent login stage
        // stage "login2" is the Passkey-enabled loginConsent login stage
        if ((stage === "login")||(stage === "login2")||(stage === "usernamepassword")) {
            this.isLogin = true;
            // vchu1: login page will not be aware of passkey anymore: this.isPasskeyAware = (stage === "login2");
        }

        // Ensure that Social Buttons appear at top of Page Node
        const pullToTop = FrCallbackType.SelectIdPCallback;
        this.step.callbacks.sort((currentCallback, otherCallback) => {
        if (currentCallback.payload.type === pullToTop) {
            return -1;
        }
        if (otherCallback.payload.type === pullToTop) {
            return 1;
        }
        return 0;
        });

        // Some callbacks don't need to render anything so forEach is used instead of map
        const componentList = [];
        let keyFromDate = Date.now();
        //console.log("step: ", this.step); //vchu1 debug
        this.step.callbacks.forEach((callback, i) => {
            // index 0 is reserved for callback_0 used in backend scripts
            const index = i + 1;

            // Special handling for Registration flow.  CORPlatformRegistration2 and onward uses stage to detect special handling, which is more accurate
            if ((authTree === 'CORPlatformRegistration')||(this.stage === 'regGetUserInfo')) {
                if (callback.getName) {
                    const name = callback.getName();
                    if ((name == "acceptedPOIStatement") || (name == "acceptedUATerms")) {
                    this.setCallbackField(callback, 'required', true);
                    }
                }
            }

            let isNextButtonDisabled = this.isCallbackRequired(callback);
            console.log('isNextButtonDisabled:', isNextButtonDisabled);
            this.nextButtonDisabledArray.push(isNextButtonDisabled);
            const existsInComponentList = (type) => find(componentList, (component) => component.type === `Fr${type}`);
            let type = callback.getType();

            //if (callback.getName) console.log("callback name: ", callback.getName());  // vchu1 debug
            //console.log("callback: ", callback);  // vchu1 debug
            //console.log("type: ", type);  // vchu1 debug

            if (type === FrCallbackType.RedirectCallback) {
                this.nextButtonVisible = false;
                if (callback.getOutputByName('trackingCookie')) {
                // save current step information for later resumption of tree.
                sessionStorage.setItem('authIndexValue', this.treeId || this.$route.params.tree);
                sessionStorage.setItem('step', JSON.stringify(this.step));
                sessionStorage.setItem('realm', this.realm);
                }
                const redirectUrl = callback.getOutputByName('redirectUrl');
                if (callback.getOutputByName('redirectMethod') === 'POST') {
                const redirectData = callback.getOutputByName('redirectData');
                const form = document.createElement('form');
                form.method = 'post';
                form.action = redirectUrl;

                Object.entries(redirectData).forEach(([key, value]) => {
                    const hiddenField = document.createElement('input');
                    hiddenField.type = 'hidden';
                    hiddenField.name = key;
                    hiddenField.value = value;

                    form.appendChild(hiddenField);
                });

                document.body.appendChild(form);
                form.submit();
                } else {
                window.location.href = redirectUrl;
                }
                return;
            }

            // Use SDK to handle backend scripts that SDK can parse
            // Reasign type to use specific component
            if (type === FrCallbackType.TextOutputCallback || type === FrCallbackType.MetadataCallback) {
                const isWebAuthnStep = FRWebAuthn.getWebAuthnStepType(this.step) !== WebAuthnStepType.None;
                const isRecovertCodeStep = FRRecoveryCodes.isDisplayStep(this.step);
                if (isWebAuthnStep) {
                // dont call the sdk twice on the same webAuthn step
                const onlyOneWebAuthn = !existsInComponentList(FrCallbackType.WebAuthnComponent);
                if (onlyOneWebAuthn) {
                    type = FrCallbackType.WebAuthnComponent;
                } else {
                    return;
                }
                } else if (isRecovertCodeStep) {
                type = FrCallbackType.RecoveryCodesComponent;
                }
            }

            // Only components that need extra props or events
            const getcomponentPropsAndEvents = (componentType) => {
                //console.log("Registration: getcomponentPropsAndEvents called"); // vchu1 debug
                const componentPropsAndEvents = {
                ChoiceCallback: () => {
                    let stage;
                    if (this.stage.ChoiceCallback) {
                    stage = this.stage.ChoiceCallback.shift();
                    }
                    return { callbackSpecificProps: { stage } };
                },
                ConfirmationCallback: () => {
                    let stage;
                    if (this.stage.ConfirmationCallback) {
                    stage = this.stage.ConfirmationCallback.shift();
                    }
                    return { callbackSpecificProps: { stage, variant: existsInComponentList(FrCallbackType.WebAuthnComponent) ? 'link' : 'primary' } };
                },
                ConsentMappingCallback: () => ({
                    callbackSpecificProps: { callbacks: this.step.callbacks },
                    listeners: ['disable-next-button', 'did-consent'],
                }),
                HiddenValueCallback: () => ({
                    listeners: ['hidden-value-callback-ref'],
                }),
                KbaCreateCallback: () => ({
                    callbackSpecificProps: { showHeader: !existsInComponentList(FrCallbackType.KbaCreateCallback) },
                    listeners: ['disable-next-button'],
                }),
                ReCaptchaCallback: () => ({
                    listeners: ['next-step-callback'],
                }),
                SelectIdPCallback: () => ({
                    callbackSpecificProps: { isOnlyCallback: this.step.callbacks.length === 1 },
                    listeners: ['hide-next-button', 'disable-next-button'],
                }),
                TextOutputCallback: () => ({
                    listeners: ['disable-next-button', 'has-scripts', 'hide-next-button', 'next-step-callback'],
                }),
                ValidatedCreatePasswordCallback: () => {
                    let stage;
                    if (this.stage.ValidatedCreatePasswordCallback) {
                        stage = this.stage.ValidatedCreatePasswordCallback.shift();
                    }
                    return {
                    callbackSpecificProps: {
                        index,
                        overrideInitialPolicies: true,
                        realm: this.realm,
                        stage,
                    },
                    listeners: ['disable-next-button', 'next-step-callback', 'update-auth-id'],
                    };
                },
                WebAuthnComponent: () => {
                    const webAuthnType = FRWebAuthn.getWebAuthnStepType(this.step);
                    const webAuthnPromise = this.createWebAuthnCallbackPromise(webAuthnType);
                    return {
                    callbackSpecificProps: { webAuthnType, webAuthnPromise },
                    };
                },
                };
                return componentPropsAndEvents[componentType] ? componentPropsAndEvents[componentType]() : {};
            };

            const { callbackSpecificProps = {}, listeners = [] } = getcomponentPropsAndEvents(type);
            const component = {
                callback,
                callbackSpecificProps,
                index,
                // if the app isn't loading update existing component props
                key: this.loading ? keyFromDate += 1 : this.componentList[i].key,
                listeners: this.getListeners({ callback, index }, listeners),
                type: this.$options.components[`Fr${type}`]
                ? `Fr${type}`
                : 'FrField',
            };

            if (component.type === 'FrField' || component.type === 'FrPasswordCallback') {
                const {
                fieldType, label, name, value,
                } = this.getField(callback, index);
                const errors = this.getTranslatePolicyFailures(callback);
                component.callbackSpecificProps = {
                errors, label, name, type: fieldType, value,
                };
                component.listeners = this.getListeners({ callback, index }, ['input']);

                // vchu1: has password callback! and is login: Include forgot password link
                // syeung: defined on above -- this.isLoginScreen = (authTree == undefined) || (authTree === 'loginConsent');
                if (this.isLoginScreen) {  // default view = login page
                    //this.forgotPasswordVisible = true;
                    // vchu1 debug: console.log('store state ',this.$store.state );
                    // vchu1 debug: console.log('AM URL ', "${AM_URL}");  // this only works in container where AM_URL is envsubst with env var value
                    // vchu1 debug: console.log('IDM REST URL ', "${IDM_REST_URL}");  // this only works in container where AM_URL is envsubst with env var value
                    // vchu1 debug: console.log('Container state ', containerState);

                    // setup specific members to note if a field is username or password
                    
                    const urlParams = new URLSearchParams(window.location.search);
                    const goto = urlParams.get('goto');
                    const passkeyParams = new URLSearchParams();
                    this.forgotPasswordURL = `${containerState.env.AM_URL}/XUI/?realm=/cor&authIndexType=service&authIndexValue=CORPlatformResetPassword`;
                    this.forgotPasskeyURL = `${containerState.env.AM_URL}/XUI/?realm=/cor&authIndexType=service&authIndexValue=CORPlatformResetPasskey`;
                    passkeyParams.append('realm', '/cor');
                    passkeyParams.append('authIndexType', 'service');
                    passkeyParams.append('authIndexValue', 'loginConsentPasskey');
                    if (goto) passkeyParams.append('goto', goto);
                    this.passkeyLoginURL = `${containerState.env.AM_URL}/XUI/?${passkeyParams.toString()}`;
                }
            }

            const hideNextButtonCallbacks = [
                FrCallbackType.ConfirmationCallback,
                FrCallbackType.DeviceProfileCallback,
                FrCallbackType.PollingWaitCallback,
                FrCallbackType.RecoveryCodesComponent,
                FrCallbackType.SuspendedTextOutputCallback,
                FrCallbackType.WebAuthnComponent,
            ];
            this.nextButtonVisible = hideNextButtonCallbacks.indexOf(type) > -1 ? false : this.nextButtonVisible;

            componentList.push(component);
        });
        this.componentList = componentList;
    },
    // needs to happen before other query params are processed
    checkNewSession() {
      return new Promise((resolve) => {
        // need to logout if query param is present and equal to newsession
        if (new URLSearchParams(this.getCurrentQueryString()).get('arg') === 'newsession') {
          SessionManager.logout().then(() => {
            resolve();
          });
        } else {
          resolve();
        }
      });
    },
    checkNodeForThemeOverride(stage) {
      if (isString(stage)) {
        this.nodeThemeId = getThemeIdFromStageString(stage);
      } else if (stage.themeId) {
        this.nodeThemeId = stage.themeId;
      }
    },
    /**
     * @description clears and sets the reentry cookie to be deleted
     */
    clearReentryToken() {
      const date = new Date();
      date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));
      document.cookie = `reentry="";expires="${date.toGMTString()}";path=/`;
    },
    /**
     * @description  Invokes WebAuthn registration or authentication
     * @param {Number} type enum number that represents WebAuthn type WebAuthnStepType.Authentication or WebAuthnStepType.Registration
     * @returns {Promise} SDK WebAuthn promise resolved when WebAuthn is completed
     */
    createWebAuthnCallbackPromise(type) {
      if (type === WebAuthnStepType.Authentication) {
        return FRWebAuthn.authenticate(this.step);
      }
      return FRWebAuthn.register(this.step);
    },
    /**
     * @description Look at the url and see if we are returning to a tree from an Email Suspend Node, Redirect Callback, or SAML.
     * Must be default route and contain the strings "suspendedId=" and "authIndexValue=" for Email Suspend Node.
     * Must contain the strings "state=" and "code=" and "scope=" for redirect callback.
     * Must contain reentry cookie for SAML redirect.
     */
    evaluateUrlParams() {
      const paramString = this.getCurrentQueryString();
      const params = new URLSearchParams(paramString);
      const realm = params.get('realm') || '/';
      const hash = window.location.hash || '';

      const createParamString = (urlParams) => {
        const ampersand = urlParams.toString().length > 1 ? '&' : '';
        let stringParams = '';
        urlParams.forEach((value, key) => {
          if (stringParams.length) {
            stringParams += '&';
          }
          if (key === 'authIndexValue' && urlParams.get('authIndexType') === 'service') {
            stringParams += `${key}=${value}`;
          } else {
            stringParams += `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
          }
        });
        return `${ampersand}${stringParams}`;
      };

      this.setPageTitle(hash, params);

      if (realm) params.delete('realm');
      // arg query parameter handled in checkNewSession method
      if (params.get('arg') === 'newsession') params.delete('arg');
      if (this.$route.name === 'login' && paramString.includes('suspendedId=') && paramString.includes('authIndexValue=')) {
        // setting params in vue data then deleting to remove redundant params from URL
        this.treeId = params.get('authIndexValue');
        params.delete('authIndexValue');
        params.delete('authIndexType');
        this.suspendedId = params.get('suspendedId');
        params.delete('suspendedId');

        const stringParams = createParamString(params);
        this.removeUrlParams();
        window.history.replaceState(null, null, `?realm=${this.realm}${stringParams}`);
      } else if (paramString.includes('state=') || paramString.includes('code=') || paramString.includes('scope=')) {
        this.state = params.get('state');
        params.delete('state');
        this.code = params.get('code');
        params.delete('code');
        this.scope = params.get('scope');
        params.delete('scope');

        // session storage is used to resume a tree after returning from a redirect
        const { authIndexValue, step, realm: stepRealm } = this.getStepFromStorage();
        this.treeId = authIndexValue;
        this.step = new FRStep(step.payload);
        this.realm = stepRealm;

        const stringParams = createParamString(params);
        this.removeUrlParams();
        window.history.replaceState(null, null, `?realm=${this.realm}${stringParams}`);
      } else if (this.hasReentryToken()) {
        const { authIndexValue, step, realm: stepRealm } = this.getStepFromStorage();
        if (authIndexValue && step && step.payload) {
          this.treeId = authIndexValue;
          this.step = new FRStep(step.payload);
          this.realm = stepRealm;
        }
        this.clearReentryToken();
      } else {
        const resourceUrlParam = params.get('resourceURL');
        if (resourceUrlParam) params.delete('resourceURL');
        // Rest does not accept the params listed in the array below as is
        // they must be transformed into the "authIndexType" and "authIndexValue" params
        // there should only be one authIndexType/Value per request
        each(['authlevel', 'module', 'service', 'user', 'resource'], (param) => {
          if (params.get(param)) {
            const resourceDefinedWithoutUrl = param === 'resource' && !resourceUrlParam;
            if (resourceDefinedWithoutUrl) {
              return;
            }

            const indexValue = param === 'resource' ? resourceUrlParam : params.get(param);

            params.delete(param);
            params.set('authIndexType', param === 'authlevel' ? 'level' : param);
            params.set('authIndexValue', indexValue);
          }
        });

        if (this.$route.params.tree) {
          this.treeId = this.$route.params.tree;
        } else if (params.get('authIndexValue') && params.get('authIndexType') === 'service') {
          this.treeId = params.get('authIndexValue');
        }

        this.removeUrlParams();
        const stringParams = createParamString(params);
        window.history.replaceState(null, null, `?realm=${this.realm}${stringParams}${hash}`);
      }
    },
    /**
     * @description gets field information
     * @param {Object} callback specific step callback
     * @param {Object} index callback index
     * @returns {Object} field props needed for Field component
     */
    getField(callback, index) {
        const callbackType = callback.getType();
        let fieldType;

        switch (callbackType) {
        case FrCallbackType.PasswordCallback:
        case FrCallbackType.ValidatedCreatePasswordCallback:
            fieldType = 'password';
            break;
        case FrCallbackType.NumberAttributeInputCallback:
            fieldType = 'number';
            break;
        default:
            fieldType = 'string';
            break;
        }

        let label = '';
        if (callback.getPrompt) {
        label = callback.getPrompt();
        } else if (callback.getOutputByName) {
        try {
            label = callback.getOutputByName('prompt').value;
        } catch (e) {
            noop();
        }
        }
        return {
        label,
        fieldType,
        name: `callback_${index}`,
        value: callback.getInputValue(),
        };
    },
    /**
     * @description Used to get link to start of tree from stepParams
     * @param {Object} stepParams desctuctured object containing tree, realmPath strings
     * @returns {string} returns string url
     */
    getLinkToTreeStart({ tree, realmPath, query: { goto, gotoOnFail } }) {
        const gotosString = `${goto ? `&goto=${encodeURIComponent(goto)}` : ''}${gotoOnFail ? `&gotoOnFail=${encodeURIComponent(gotoOnFail)}` : ''}`;
        let prefix = '';
        if (window.location.hostname.startsWith("login")) prefix = '/am/XUI';
        return `${prefix}/?realm=${realmPath}&authIndexType=service&authIndexValue=${tree}${gotosString}`;
    },
    /**
     * @description Used to get listeners for callback components
     * @param {Object} properties object of any properties needed to have listeners context
     * @param {Array} listenerArray array of string names to populate component listeners
     * @returns {Object} returns object populated with specified listener functions
     */
    getListeners({ callback, index }, listenerArray = []) {
        const listeners = {
        'did-consent': (consent) => {
            this.step.callbacks.forEach((callbackItem) => { callbackItem.setInputValue(consent); });
        },
        'disable-next-button': (bool) => {
            this.nextButtonDisabledArray.splice(index, 1, bool);
        },
        'has-scripts': (appendScript) => {
            this.showScriptElms = true;
            // listen on body.appendchild and append to #body-append-el insted
            const observer = new MutationObserver((records) => {
            const nodeList = records[records.length - 1].addedNodes || [];
            Array.prototype.forEach.call(nodeList, (node) => {
                document.getElementById('body-append-el').appendChild(node);
            });
            observer.disconnect();
            });
            observer.observe(document.body, { childList: true });
            // only hide next button if we know it should be hidden (webAuthn, deviceId)
            if (this.backendScriptsIdsContains('clientScriptOutputData')) {
            this.nextButtonVisible = false;
            }
            setTimeout(() => {
            this.$nextTick(appendScript);
            }, 20);
        },
        'hide-next-button': (bool) => {
            this.nextButtonVisible = !bool;
        },
        'hidden-value-callback-ref': (ref) => {
            this.hiddenValueCallbacksRefs.push(ref);
        },
        'next-step-callback': (cb) => {
            this.nextStepCallbacks.push(cb);
        },
        'update-auth-id': (authId) => {
            this.step.payload.authId = authId;
        },
        // event emited from FrField
        input: (value) => {
            if (callback && callback.setInputValue) {
            callback.setInputValue(value);
            if (value) {
                this.nextButtonDisabledArray.splice(index, 1, false);
            } else if (this.isCallbackRequired(callback)) {
                this.nextButtonDisabledArray.splice(index, 1, true);
            }
            }
        },
        };
        return listenerArray.reduce((acc, listener) => ({ ...acc, [listener]: listeners[listener] }), {});
    },
    /**
     * @description If session storage has step information, extract it and clear session storage
     * @returns {Object} two properties needed to resume tree: step and authIndex value
     */
    getStepFromStorage() {
        const step = sessionStorage.getItem('step');
        const authIndexValue = sessionStorage.getItem('authIndexValue');
        const realm = sessionStorage.getItem('realm');
        if (step !== null && authIndexValue !== null && realm !== null) {
        sessionStorage.removeItem('step');
        sessionStorage.removeItem('authIndexValue');
        sessionStorage.removeItem('realm');
        return { step: JSON.parse(step), authIndexValue, realm };
        }
        return { step: undefined, authIndexValue: undefined, realm: undefined };
    },
    getStepParams() {
        const paramString = this.getCurrentQueryString();
        const paramsObj = this.parseParameters(paramString);
        if (paramsObj.authIndexValue) {
        paramsObj.authIndexValue = decodeURI(paramsObj.authIndexValue);
        }
        const stepParams = {
        query: {},
        tree: this.treeId || this.$route.params.tree || undefined,
        realmPath: this.realm,
        };

        // Set the SDK tree property from the URL if a tree is being accessed and none of the other local variables have defined one
        if (paramsObj.authIndexType === 'service' && paramsObj.authIndexValue && typeof stepParams.tree === 'undefined') {
        stepParams.tree = paramsObj.authIndexValue;
        }

        // remove tree from stepParams when undefined
        if (stepParams.tree === undefined || stepParams.tree === 'undefined') {
        delete stepParams.tree;
        }

        if (this.suspendedId) {
        stepParams.query.suspendedId = this.suspendedId;
        stepParams.query.goto = (paramsObj.goto) ? decodeURIComponent(paramsObj.goto) : undefined;
        stepParams.query.gotoOnFail = (paramsObj.gotoOnFail) ? decodeURIComponent(paramsObj.gotoOnFail) : undefined;
        } else {
        stepParams.query = paramsObj;
        stepParams.query.code = this.code ? this.code : undefined;
        stepParams.query.state = this.state ? this.state : undefined;
        stepParams.query.scope = this.scope ? this.scope : undefined;
        stepParams.query.goto = (paramsObj.goto) ? decodeURIComponent(paramsObj.goto) : undefined;
        stepParams.query.gotoOnFail = (paramsObj.gotoOnFail) ? decodeURIComponent(paramsObj.gotoOnFail) : undefined;
        }
        // stepParams.query.realm never needs to be included. We are already sending stepParams.realmPath which is what the
        // sdk uses to build the authenticate url ('/am/json/realms/root/realms/alpha/authenticate').
        // When realm is included ('/am/json/realms/root/realms/alpha/authenticate?realm=/alpha') this can confuse
        // some parts of am like SAML (see FRAAS-6573).
        delete stepParams.query.realm;

        // locale is set by the request header through sdk config. Sending this query param causes unexpected responses from AM
        // with differences between chains and trees. More consistent to just rely on the request header (see IAM-1440)
        delete stepParams.query.locale;
        return stepParams;
    },
    getTranslatePolicyFailures(callback) {
        const failedPolicies = callback.getFailedPolicies
        ? callback.getFailedPolicies()
        : [];
        return failedPolicies.map((policy) => {
        const parsedPolicy = JSON.parse(policy);
        return this.$t(`common.policyValidationMessages.${parsedPolicy.policyRequirement}`, parsedPolicy.params);
        });
    },
    /**
     * @description Returns boolean true if reentry cookie is set
     * @returns {Boolean}
     */
    hasReentryToken() {
        return !!document.cookie
        .split('; ')
        .find((row) => row.startsWith('reentry='));
    },
    /**
     * @description Determines if passed in callback is set to required or has a required policy
     * @param {Object} callback - callback to check if required
     * @returns {Boolean} True if passed in callback is required
     */
    isCallbackRequired(callback) {
        const requiredOutput = callback.getOutputByName('required');
        if (requiredOutput === true && callback.getType() !== 'BooleanAttributeInputCallback') {
        return true;
        }
        const policyOutput = callback.getOutputByName('policies');
        if (has(policyOutput, 'policies')) {
        const requiredPolicy = policyOutput.policies.find((policy) => policy.policyId === 'required');
        if (has(requiredPolicy, 'policyRequirements') && requiredPolicy.policyRequirements.includes('REQUIRED')) {
            return true;
        }
        }
        return false;
    },
    /**
     * @description Returns boolean true if payload has session timeout error code
     * @param {Object} payload - step payload data
     * @returns {Boolean}
     */
    isSessionTimedOut(payload) {
        return (
        (payload.detail && payload.detail.errorCode === '110')
        || (this.suspendedId && payload.code.toString() === '401')
        );
    },
    /**
     * @description Gets callbacks needed for authentication when this.step is undefined, and submits callback values when
     * this.step is defined. Then determines based on step.type what action to take.
     */
    nextStep(event, preventClear) {
        if (event) {
        event.preventDefault();
        }
        // for when no change is expected between steps (stops a flash of white from rerender)
        if (!preventClear) {
        this.loading = true;
        this.showScriptElms = false;
        this.hiddenValueCallbacksRefs = [];
        }
        this.loginFailure = false;
        this.linkToTreeStart = '';

        // invoke callbacks before nextStep
        // if a callback returns a promise, the promise is pushed to an array
        const callbackPromises = [];
        while (this.nextStepCallbacks.length) {
            const cb = this.nextStepCallbacks.shift();
            const returnPromise = cb();
            if (returnPromise && typeof returnPromise === 'object' && typeof returnPromise.then === 'function') {
                callbackPromises.push(returnPromise);
            }
        }
        // the array of promises is waited on to continue execution until all have resolved
        if (callbackPromises.length) {
            Promise.all(callbackPromises).finally(
                () => this.nextStep(event, preventClear),
        );
        return;
        }

        const stepParams = this.getStepParams();
        FRAuth.next(this.step, stepParams)
        .then((step) => {
            let initialStep;
            const realmAndTreeInitialStep = JSON.parse(sessionStorage.getItem('initialStep'));
            const realmAndTreeKey = `${stepParams.realmPath}/${stepParams.tree || ''}`;
            if (realmAndTreeInitialStep && realmAndTreeInitialStep.key === realmAndTreeKey) {
            initialStep = new FRStep(realmAndTreeInitialStep.step.payload);
            } else if (step.type !== 'LoginFailure') {
            sessionStorage.setItem('initialStep', JSON.stringify(
                {
                key: realmAndTreeKey,
                step,
                },
            ));
            }
            const previousStep = this.step;
            this.step = step;

            // these step params only need to be sent one time
            if (this.code || this.state || this.scope) {
            this.code = undefined;
            this.state = undefined;
            this.scope = undefined;
            }

            switch (step.type) {
            case 'LoginSuccess':
                // If we have a session token, get user information
                sessionStorage.removeItem('initialStep');
                this.getIdFromSession()
                .then(this.getUserInfo)
                .then((userObj) => {
                    let isAdmin = false;
                    const rolesArray = userObj.data.roles;

                    if (rolesArray.includes('ui-global-admin') || rolesArray.includes('ui-realm-admin')) {
                    isAdmin = true;
                    }
                    return this.verifyGotoUrlAndRedirect(step.getSuccessUrl(), this.realm, isAdmin);
                })
                .then((res) => {
                    window.location.href = res;
                })
                .catch(() => {
                    // attempt to redirect user on failure
                    this.verifyGotoUrlAndRedirect(step.getSuccessUrl(), this.realm, false)
                    .then((res) => {
                        window.location.href = res;
                    });
                });
                break;
            case 'LoginFailure':
                this.loading = true;
                if (this.retry && this.isSessionTimedOut(step.payload)) {
                this.retry = false;
                this.retryWithNewAuthId(previousStep, stepParams);
                } else if (this.suspendedId && this.isSessionTimedOut(step.payload)) {
                this.errorMessage = step.payload.message || this.$t('login.loginFailure');
                this.linkToTreeStart = this.getLinkToTreeStart(stepParams);
                this.loading = false;
                } else {
                this.errorMessage = step.payload.message || this.$t('login.loginFailure');
                this.redirectToFailure(step);
                this.step = initialStep;
                this.retry = true;
                if (this.step && this.step.callbacks) {
                    this.componentList = [];
                    this.buildTreeForm();
                }
                this.loading = false;
                }
                this.loginFailure = true;
                break;
            default:
                // retry only when previous was undefined (first step)
                this.retry = !previousStep;

                // check if we are still polling with the same callbacks and set loading to false
                if (preventClear && previousStep) {
                const arrayLengthMatch = previousStep.callbacks.length === step.callbacks.length;
                let sameCallbacks;
                if (arrayLengthMatch) {
                    sameCallbacks = previousStep.callbacks.every((prevCallback, i) => prevCallback.getType() === step.callbacks[i].getType());
                }
                this.loading = !(arrayLengthMatch && sameCallbacks);
                this.showScriptElms = arrayLengthMatch && sameCallbacks;
                }
                // setup the form based on callback info/values obtained from this.step
                this.buildTreeForm();
                this.loading = false;
                break;
            }
            this.$emit('set-theme', this.realm, this.treeId, this.nodeThemeId);
        },
        () => {
            this.$emit('component-ready', 'error');
            this.errorMessage = this.$t('login.issueConnecting');
            this.redirectToFailure(this.step);
            this.loading = false;
        });
    },
    /**
     * Determine if there is a gotoOnFail parameter. If it exists, verify and redirect to that url or hash
     * Redirect to failureUrl when it exists, and display login failure message if not
     *
     * @param {Object} step - callback metadata containing url of failure
     */
    redirectToFailure(step) {
        const urlParams = new URLSearchParams(window.location.search);
        const gotoOnFail = urlParams.get('gotoOnFail');

        if (gotoOnFail) {
        this.verifyGotoUrlAndRedirect(gotoOnFail, this.realm, false, true)
            .then((res) => {
            if (res && res.length) {
                window.location.href = encodeURI(res);
            } else if (has(step, 'payload.detail.failureUrl') && step.payload.detail.failureUrl.length) {
                window.location.href = step.payload.detail.failureUrl;
            }
            })
            .catch((error) => {
            this.displayNotification('IDMMessages', 'error', error.response.data.message);
            });
        } else if (has(step, 'payload.detail.failureUrl') && step.payload.detail.failureUrl.length) {
        window.location.href = step.payload.detail.failureUrl;
        }
    },
    removeUrlParams() {
        // remove query params from the url
        window.history.replaceState(null, null, window.location.pathname);
        // if a tree is defined reset the hash to the proper tree
        if (this.treeId) {
        window.location.hash = `service/${this.treeId}`;
        }
    },
    /**
     * Retry a previously failed step with a new authId. The new authId is acquired by calling FRAuth.next with no step.
     * Then a this.nextStep is called with the previously failed step and new authId
     *
     * @param {Object} previousStep - previous step data with prototype intact
     * @param {Object} stepParams - step params
     *
     */
    retryWithNewAuthId(previousStep, stepParams) {
        FRAuth.next(undefined, stepParams)
        .then((step) => {
            const { authId } = step.payload;
            this.step = previousStep;
            if (has(this.step, 'payload')) {
            this.step.payload.authId = authId;
            }
            this.nextStep();
        });
    },
    setRealm(config) {
        this.realm = config ? config.data.realm : '/';
    },
    /**
     * Sets page title depending on the journey service type
     *
     * @param {String} hash - window.location.hash
     * @param {Oject} params - params.get(string)
     *
     */
     setPageTitle(hash, params) {
      // For the following registration url params:
      // &authIndexType=service&authIndexValue=Registration
      if (params && params.get('authIndexType') === 'service' && params.get('authIndexValue')) {
        document.title = params.get('authIndexValue');

      // For the following registration url:
      // #/service/Registration
      } else if (hash.includes('service')) {
        const hashSplit = hash.split('/');
        const serviceIndex = hashSplit.indexOf('service');
        // makes sure that the hashSplit array has at least one more element after 'service'
        const serviceValueExists = (serviceIndex + 2) >= hashSplit.length;

        if (serviceIndex !== -1 && serviceValueExists) {
          const title = hashSplit[serviceIndex + 1];
          // in the event that the remaining url contains query params
          if (title.includes('?')) {
            const titleSplitByParam = title.split('?')[0];
            document.title = titleSplitByParam;
          } else {
            document.title = title;
          }
        }
      }
    },
    searchField(name) {
        for (const comp of this.componentList) {
            if (name === "[NameCallback]") {  // 
                // Looking for the user name (before validation)
                if (comp.callback.getType() === "NameCallback") return comp;
                /*let input = comp.callback.payload.input;
                if (input && (input.length > 0)) {
                    for (const ele of input) {
                        if (ele.name === "IDToken1") return comp;
                    }
                }*/
            } else if (name === "[ValidatedCreateUsernameCallback]") {
                if (comp.callback.getType() === "ValidatedCreateUsernameCallback") return comp;
            } else if (name === "[ValidatedCreatePasswordCallback]") {
                // Looking for the password (after validation)
                if (comp.callback.getType() === "ValidatedCreatePasswordCallback") return comp;
                /*
                let input = comp.callback.payload.input;
                if (input && (input.length > 0)) {
                    for (const ele of input) {
                        if (ele.name === "IDToken2") return comp;
                    }
                }*/
            } else if (name === "[TextOutputCallback]") {
                if (comp.callback.getType() === "TextOutputCallback") return comp;
            } else {
                // Attribute etc
                let compName = this.getFieldName(comp);
                if (name === compName) return comp;
            }
        };
        return undefined;   // not found
    },
    getFieldName(component) {
        let callback = component.callback;
        if (callback.getName) return callback.getName();

        // No name.  See if it is userName or password
        let type = callback.getType();
        if (type === 'NameCallback')
            return '[NameCallback]';
        else if (type === 'ValidatedCreateUsernameCallback')
            return '[ValidatedCreateUsernameCallback]';
        else if (type === 'ValidatedCreatePasswordCallback')
            return '[ValidatedCreatePasswordCallback]';

        return "";
        /*let output = component.callback.payload.output;
        if (output && output.length > 0) {
            for (const ele of output) {
                if (ele.name === "name") return ele.value;
            }
        }
        return "";*/
    },
    setCallbackField(callback, field, value) {
        if (callback.payload) {
            let output = callback.payload.output;
            if (output && output.length > 0) {
                for (const ele of output) {
                    if (ele.name === field) {
                        ele.value = value;
                        return true;
                    }
                }
            }
        }
        return false;
    },
    commOpt1Change(value) {
        /* event handler @change is not required because the v-on in the component listeners
        include the 'input' handler which sets the value of the component.  Ex.
        <BFormCheckbox value="true" @change="poiChange"
            v-on="{
                'next-step': (event, preventClear) => {
                nextStep(event, preventClear);
                },
                ...poiField.listeners}">
            I accept the following <img src='images/pdficon.gif' /> <a href='https://richmond.ca/__shared/assets/myrichmondprivacypolicy54739.pdf' target='_blank'>Privacy of Information Statement</a>
        </BFormCheckbox>

        this.searchField("CommOpt1").callback.setInputValue(value.toString());
        */
    },
    commOpt2Change(value) {
        // this.searchField("CommOpt2").callback.setInputValue(value.toString());
    },
    acceptedUATChange(value) {
        // this.searchField("acceptedUATerms").callback.setInputValue(value.toString());
    },
    poiChange(value) {
        // this.searchField("acceptedPOIStatement").callback.setInputValue(value.toString());
    },
    setField(comp, field, value) {
        if (comp.callback.payload) {
            let output = comp.callback.payload.output;
            if (output && output.length > 0) {
                for (const ele of output) {
                    if (ele.name === field) {
                        ele.value = value;
                        return true;
                    }
                }
            }
        }
        return false;
    },
    },
    computed: {
        buttonTextLocalized() {
            let submitButtonTextOverride = null;
            try {
                submitButtonTextOverride = this.getLocalizedString(this.stage.submitButtonText, i18n.locale);
            } catch (e) {
                return this.buttonText || this.$t('login.next');
            }

            return submitButtonTextOverride || this.buttonText || this.$t('login.next');
        },
        forgotPasswordVisible() {
            return this.isLoginScreen && this.stage.forgotPasswordVisible !== false;
        },
        forgotPasswordLocalized() {
            return this.$t('login.forgotPassword');
        },
        forgotPasskeyLocalized() {
            return this.$t('login.forgotPasskey');
        },
        passkeyLoginLocalized() {
            return this.$t('login.passkeyLogin');
        },
        pageFooterLocalized() {
            try {
                return this.$sanitize(this.getLocalizedString(this.stage.pageFooter, i18n.locale));
            } catch (e) {
                return null;
            }
        },
        nextButtonDisabled() {
            // checks if there are any true bool values in array
            return this.nextButtonDisabledArray.some((bool) => bool);
        },
        sanitizedContent() {
            return this.$sanitize(this.journeyJustifiedContent);
        },
        sanitizedFooter() {
            return this.$sanitize(this.journeyFooter);
        },
        sanitizedHeader() {
            return this.$sanitize(this.journeyHeader);
        },
        stage() {
            try {
                return JSON.parse(this.step.getStage());
            } catch (e) {
                if (this.step?.getStage() !== undefined) {
                return this.step.getStage();
                }
                return '';
            }
        },
        sharedComponentList() { // make componentList reactive so Registration gets re-rendered when componentList changes
            return this.componentList;
        },
        corLogoPath() {
            return require('@forgerock/platform-shared/src/assets/images/login-logo.png');
        },
        extraComponents() {
            if (this.componentList) {
                let userNameField = this.userNameField; // in stage getUserInfo, the userNameField is TextOutputCallback and should be filtered out
                let extra = this.componentList.filter((comp) => {
                    if (userNameField === comp) return false;   // if the component is already userNameField, then filter it out

                    let names = ['[ValidatedCreateUsernameCallback]', '[NameCallback]', '[ValidatedCreatePasswordCallback]',
                        'givenName', 'sn', 'dob', 'acceptedPOIStatement', 'homePhone', 'mobile',
                        'telephoneNumber', 'homeUnitNumber', 'homeHouseNumber', 'homeStreetName',
                        'homeAddlInfo1', 'homeAddlInfo2', 'homeCity', 'homeProvince', 'homeCountry',
                        'homePostalCode', 'CommOpt1', 'CommOpt2', 'acceptedUATerms'];
                    let name = this.getFieldName(comp);
                    return !names.includes(name);
                });
                return extra;
            } else {
                return [];
            }
        }
    }
};
</script>

<style lang="scss" scoped>
#callbacksPanel ::v-deep {
    span.material-icons {
    line-height: 22px;
    }

    .callback-component:not(:last-of-type):not(.hidden) {
    margin-bottom: 1rem;
    }

    .hide-polling-spinner ~ .polling-spinner-container {
    display: none;
    }

    .max-width-600 {
    max-width: 600px;
    }

    .journey-card {
    background-color: $white;
    }
}

.card-footer {
    border: 0;
    padding: 0;
}

input:focus,
input:not(:placeholder-shown) {
    padding-top: $input-btn-padding-y + $input-btn-padding-y * calc(2 / 3);
    padding-bottom: calc($input-btn-padding-y / 3);

    ~ label {
    padding-top: calc($input-btn-padding-y / 3);
    padding-bottom: 0;
    font-size: 12px;
    }
}

@media (min-width: 768px) {
    .w-md-50 {
    width: 50% !important;
    }
}

@media (max-width: 576px) {
    .fr-fullscreen-mobile {
    .container,
    .col-lg-12 {
        margin: 0;
        padding: 0;
        height: 100%;
    }
    }
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.fullwidth {
  width: 100%;
}
</style>