var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BContainer',{attrs:{"fluid":""}},[(_vm.poiField)?_c('BFormRow',{staticClass:"mb-2"},[_c('BCol',{attrs:{"cols":"12"}},[_c('BCard',{staticClass:"pb-3 fullwidth",attrs:{"no-body":"","header":"Privacy of Information Statement"}},[_c('BCardBody',[_c('BFormCheckbox',_vm._g({attrs:{"value":"true","plain":"true"}},Object.assign({}, {'next-step': function (event, preventClear) {
                        _vm.nextStep(event, preventClear);
                        }},
                        _vm.poiField.listeners)),[_vm._v(" I accept the following "),_vm._v(" "),_c('a',{attrs:{"href":"https://richmond.ca/__shared/assets/myrichmondprivacypolicy54739.pdf","target":"_blank"}},[_vm._v("Privacy of Information Statement")])])],1)],1)],1)],1):_vm._e(),(_vm.userNameField)?_c('BFormRow',{staticClass:"mb-2"},[_c('BCol',{attrs:{"cols":"12"}},[_c('BCard',{staticClass:"pb-3 fullwidth",attrs:{"no-body":"","header":"User Information"}},[_c('BCardBody',[_c(_vm.userNameField.type,_vm._g(_vm._b({key:_vm.userNameField.key,tag:"Component",staticClass:"callback-component",attrs:{"callback":_vm.userNameField.callback,"index":_vm.userNameField.index,"step":_vm.step}},'Component',Object.assign({}, _vm.userNameField.callbackSpecificProps),false),Object.assign({}, {'next-step': function (event, preventClear) {
                        _vm.nextStep(event, preventClear);
                        }},
                        _vm.userNameField.listeners))),(_vm.stepStage==='regGetLoginEmail')?_c('div',[_vm._v("Enter email address for registration")]):_vm._e(),(_vm.givenNameField)?_c(_vm.givenNameField.type,_vm._g(_vm._b({key:_vm.givenNameField.key,tag:"Component",staticClass:"callback-component",attrs:{"callback":_vm.givenNameField.callback,"index":_vm.givenNameField.index,"step":_vm.step}},'Component',Object.assign({}, _vm.givenNameField.callbackSpecificProps),false),Object.assign({}, {'next-step': function (event, preventClear) {
                        _vm.nextStep(event, preventClear);
                        }},
                        _vm.givenNameField.listeners))):_vm._e(),(_vm.snField)?_c(_vm.snField.type,_vm._g(_vm._b({key:_vm.snField.key,tag:"Component",staticClass:"callback-component",attrs:{"callback":_vm.snField.callback,"index":_vm.snField.index,"step":_vm.step}},'Component',Object.assign({}, _vm.snField.callbackSpecificProps),false),Object.assign({}, {'next-step': function (event, preventClear) {
                        _vm.nextStep(event, preventClear);
                        }},
                        _vm.snField.listeners))):_vm._e(),(_vm.dobField)?_c(_vm.dobField.type,_vm._g(_vm._b({key:_vm.dobField.key,tag:"Component",staticClass:"callback-component",attrs:{"callback":_vm.dobField.callback,"index":_vm.dobField.index,"step":_vm.step}},'Component',Object.assign({}, _vm.dobField.callbackSpecificProps),false),Object.assign({}, {'next-step': function (event, preventClear) {
                        _vm.nextStep(event, preventClear);
                        }},
                        _vm.dobField.listeners))):_vm._e(),(_vm.dobField)?_c('div',{staticClass:"small text-muted",staticStyle:{"padding-bottom":"1em"}},[_vm._v("Date of birth must be YYYYMMDD, ex. 19801220 for December 20, 1980")]):_vm._e(),(_vm.passwordField && _vm.isLegacy)?_c(_vm.passwordField.type,_vm._g(_vm._b({key:_vm.passwordField.key,tag:"Component",staticClass:"callback-component",attrs:{"callback":_vm.passwordField.callback,"index":_vm.passwordField.index,"step":_vm.step}},'Component',Object.assign({}, _vm.passwordField.callbackSpecificProps),false),Object.assign({}, {'next-step': function (event, preventClear) {
                        _vm.nextStep(event, preventClear);
                        }},
                        _vm.passwordField.listeners))):_vm._e()],1)],1)],1)],1):_vm._e(),(_vm.homePhoneField)?_c('BFormRow',{staticClass:"mb-2"},[_c('BCol',{attrs:{"cols":"12"}},[_c('BCard',{staticClass:"pb-3 fullwidth",attrs:{"no-body":"","header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-phone",modifiers:{"collapse-phone":true}}]},[_c('span',{staticClass:"when-closed"},[_vm._v("[+]")]),_c('span',{staticClass:"when-open"},[_vm._v("[-]")]),_vm._v(" Optional: Phone Numbers")])]},proxy:true}],null,false,946718663)},[_c('BCollapse',{attrs:{"id":"collapse-phone"}},[_c('BCardBody',[(_vm.homePhoneField)?_c(_vm.homePhoneField.type,_vm._g(_vm._b({key:_vm.homePhoneField.key,tag:"Component",staticClass:"callback-component",attrs:{"callback":_vm.homePhoneField.callback,"index":_vm.homePhoneField.index,"step":_vm.step}},'Component',Object.assign({}, _vm.homePhoneField.callbackSpecificProps),false),Object.assign({}, {'next-step': function (event, preventClear) {
                            _vm.nextStep(event, preventClear);
                            }},
                            _vm.homePhoneField.listeners))):_vm._e(),(_vm.mobileField)?_c(_vm.mobileField.type,_vm._g(_vm._b({key:_vm.mobileField.key,tag:"Component",staticClass:"callback-component",attrs:{"callback":_vm.mobileField.callback,"index":_vm.mobileField.index,"step":_vm.step}},'Component',Object.assign({}, _vm.mobileField.callbackSpecificProps),false),Object.assign({}, {'next-step': function (event, preventClear) {
                            _vm.nextStep(event, preventClear);
                            }},
                            _vm.mobileField.listeners))):_vm._e(),(_vm.telephoneNumberField)?_c(_vm.telephoneNumberField.type,_vm._g(_vm._b({key:_vm.telephoneNumberField.key,tag:"Component",staticClass:"callback-component",attrs:{"callback":_vm.telephoneNumberField.callback,"index":_vm.telephoneNumberField.index,"step":_vm.step}},'Component',Object.assign({}, _vm.telephoneNumberField.callbackSpecificProps),false),Object.assign({}, {'next-step': function (event, preventClear) {
                            _vm.nextStep(event, preventClear);
                            }},
                            _vm.telephoneNumberField.listeners))):_vm._e()],1)],1)],1)],1)],1):_vm._e(),(_vm.unitField)?_c('BFormRow',{staticClass:"mb-2"},[_c('BCol',{attrs:{"cols":"12"}},[_c('BCard',{staticClass:"pb-3 fullwidth",attrs:{"no-body":"","header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-home",modifiers:{"collapse-home":true}}]},[_c('span',{staticClass:"when-closed"},[_vm._v("[+]")]),_c('span',{staticClass:"when-open"},[_vm._v("[-]")]),_vm._v(" Optional: Mailing Address")])]},proxy:true}],null,false,3887276468)},[_c('BCollapse',{attrs:{"id":"collapse-home"}},[_c('BCardBody',[(_vm.unitField)?_c(_vm.unitField.type,_vm._g(_vm._b({key:_vm.unitField.key,tag:"Component",staticClass:"callback-component",attrs:{"callback":_vm.unitField.callback,"index":_vm.unitField.index,"step":_vm.step}},'Component',Object.assign({}, _vm.unitField.callbackSpecificProps),false),Object.assign({}, {'next-step': function (event, preventClear) {
                            _vm.nextStep(event, preventClear);
                            }},
                            _vm.unitField.listeners))):_vm._e(),(_vm.houseNumberField)?_c(_vm.houseNumberField.type,_vm._g(_vm._b({key:_vm.houseNumberField.key,tag:"Component",staticClass:"callback-component",attrs:{"callback":_vm.houseNumberField.callback,"index":_vm.houseNumberField.index,"step":_vm.step}},'Component',Object.assign({}, _vm.houseNumberField.callbackSpecificProps),false),Object.assign({}, {'next-step': function (event, preventClear) {
                            _vm.nextStep(event, preventClear);
                            }},
                            _vm.houseNumberField.listeners))):_vm._e(),(_vm.streetField)?_c(_vm.streetField.type,_vm._g(_vm._b({key:_vm.streetField.key,tag:"Component",staticClass:"callback-component",attrs:{"callback":_vm.streetField.callback,"index":_vm.streetField.index,"step":_vm.step}},'Component',Object.assign({}, _vm.streetField.callbackSpecificProps),false),Object.assign({}, {'next-step': function (event, preventClear) {
                            _vm.nextStep(event, preventClear);
                            }},
                            _vm.streetField.listeners))):_vm._e(),(_vm.addl1Field)?_c(_vm.addl1Field.type,_vm._g(_vm._b({key:_vm.addl1Field.key,tag:"Component",staticClass:"callback-component",attrs:{"callback":_vm.addl1Field.callback,"index":_vm.addl1Field.index,"step":_vm.step}},'Component',Object.assign({}, _vm.addl1Field.callbackSpecificProps),false),Object.assign({}, {'next-step': function (event, preventClear) {
                            _vm.nextStep(event, preventClear);
                            }},
                            _vm.addl1Field.listeners))):_vm._e(),(_vm.addl2Field)?_c(_vm.addl2Field.type,_vm._g(_vm._b({key:_vm.addl2Field.key,tag:"Component",staticClass:"callback-component",attrs:{"callback":_vm.addl2Field.callback,"index":_vm.addl2Field.index,"step":_vm.step}},'Component',Object.assign({}, _vm.addl2Field.callbackSpecificProps),false),Object.assign({}, {'next-step': function (event, preventClear) {
                            _vm.nextStep(event, preventClear);
                            }},
                            _vm.addl2Field.listeners))):_vm._e(),(_vm.cityField)?_c(_vm.cityField.type,_vm._g(_vm._b({key:_vm.cityField.key,tag:"Component",staticClass:"callback-component",attrs:{"callback":_vm.cityField.callback,"index":_vm.cityField.index,"step":_vm.step}},'Component',Object.assign({}, _vm.cityField.callbackSpecificProps),false),Object.assign({}, {'next-step': function (event, preventClear) {
                            _vm.nextStep(event, preventClear);
                            }},
                            _vm.cityField.listeners))):_vm._e(),(_vm.provField)?_c(_vm.provField.type,_vm._g(_vm._b({key:_vm.provField.key,tag:"Component",staticClass:"callback-component",attrs:{"callback":_vm.provField.callback,"index":_vm.provField.index,"step":_vm.step}},'Component',Object.assign({}, _vm.provField.callbackSpecificProps),false),Object.assign({}, {'next-step': function (event, preventClear) {
                            _vm.nextStep(event, preventClear);
                            }},
                            _vm.provField.listeners))):_vm._e(),(_vm.countryField)?_c(_vm.countryField.type,_vm._g(_vm._b({key:_vm.countryField.key,tag:"Component",staticClass:"callback-component",attrs:{"callback":_vm.countryField.callback,"index":_vm.countryField.index,"step":_vm.step}},'Component',Object.assign({}, _vm.countryField.callbackSpecificProps),false),Object.assign({}, {'next-step': function (event, preventClear) {
                            _vm.nextStep(event, preventClear);
                            }},
                            _vm.countryField.listeners))):_vm._e(),(_vm.postalField)?_c(_vm.postalField.type,_vm._g(_vm._b({key:_vm.postalField.key,tag:"Component",staticClass:"callback-component",attrs:{"callback":_vm.postalField.callback,"index":_vm.postalField.index,"step":_vm.step}},'Component',Object.assign({}, _vm.postalField.callbackSpecificProps),false),Object.assign({}, {'next-step': function (event, preventClear) {
                            _vm.nextStep(event, preventClear);
                            }},
                            _vm.postalField.listeners))):_vm._e()],1)],1)],1)],1)],1):_vm._e(),(_vm.comm1Field)?_c('BFormRow',{staticClass:"mb-2"},[_c('BCol',{attrs:{"cols":"12"}},[_c('BCard',{staticClass:"pb-3 fullwidth",attrs:{"header":"Opt-in Communication Options"}},[_c('BCardBody',[_c('div',{staticClass:"mb-2"},[_vm._v("By selecting yes, you consent to...")]),_c('BFormCheckbox',_vm._g({staticClass:"mb-2",staticStyle:{"text-align":"left"},attrs:{"value":"true","plain":"true"}},Object.assign({}, {'next-step': function (event, preventClear) {
                    _vm.nextStep(event, preventClear);
                    }},
                    _vm.comm1Field.listeners)),[_vm._v(" receiving email regarding community programs, services and events from the City of Richmond ")]),_c('BFormCheckbox',_vm._g({staticStyle:{"text-align":"left"},attrs:{"value":"true","plain":"true"}},Object.assign({}, {'next-step': function (event, preventClear) {
                    _vm.nextStep(event, preventClear);
                    }},
                    _vm.comm2Field.listeners)),[_vm._v(" receiving email regarding City news releases and announcements")]),_c('div',{staticClass:"mt-3 sm"},[_vm._v("You may update these opt-in communication consent settings any time after this registration process at MyRichmond Profile & Settings")])],1)],1)],1)],1):_vm._e(),(_vm.uatField)?_c('BFormRow',{staticClass:"mb-2"},[_c('BCol',{attrs:{"cols":"12"}},[_c('BCard',{staticClass:"pb-3 fullwidth",attrs:{"header":"User Agreement Terms"}},[_c('BCardBody',[_c('BFormCheckbox',_vm._g({attrs:{"value":"true","plain":"true"}},Object.assign({}, {'next-step': function (event, preventClear) {
                        _vm.nextStep(event, preventClear);
                        }},
                        _vm.uatField.listeners)),[_vm._v(" I accept the following "),_vm._v(" "),_c('a',{attrs:{"href":"https://richmond.ca/__shared/assets/profileterms46203.pdf","target":"_blank"}},[_vm._v("User Agreement Terms")])])],1)],1)],1)],1):_vm._e(),_c('BFormRow',{staticClass:"mb-2"},[_c('BCol',{attrs:{"cols":"12"}},[_vm._l((_vm.extraComponents),function(component){return [_c(component.type,_vm._g(_vm._b({key:component.key,tag:"Component",staticClass:"callback-component",attrs:{"callback":component.callback,"index":component.index,"step":_vm.step}},'Component',Object.assign({}, component.callbackSpecificProps),false),Object.assign({}, {'next-step': function (event, preventClear) {
                _vm.nextStep(event, preventClear);
                }},
                component.listeners)))]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }